import React from 'react';
import facebookIcon from './facebook_icon.png'
import instagramIcon from './instagram_icon.png'
import youtubeIcon from './youtube_icon.png'
import websiteicon from './website_icon.png'
import {LinkBox} from "../../links/Links";
import './SocialMediaMenu.css'

export default function SocialMediaMenu() {

    const facebook_URL =  "https://web.facebook.com/profile.php?id=61551885693815"
    const instagram_URL = "https://www.instagram.com/redaulafutura.tarapaca/?hl=es-la"
    const youtube_URL =  "https://youtube.com/@redaulafuturatarapaca?si=L-GIXm-nxBjqLYSD"
    const website_URL =  "https://redaulafuturatarapaca.cl/"


    return (
        <div className={"social-media-menu-container"}>
            <div className={"menu social-media-menu"}>
                <nav>
                    <ul className={"flex"} style={{alignItems: "center"}}>
                        <li>
                            Síguenos en:
                        </li>
                        <li><LinkBox to={instagram_URL}><img
                            style={{width: "2rem", height: "auto"}} src={instagramIcon}
                            alt={"instagram_icon"}/></LinkBox>
                        </li>
                        <li>
                            <LinkBox to={facebook_URL}><img
                                style={{width: "2rem", height: "auto"}} src={facebookIcon}
                                alt={"facebook_icon"}/></LinkBox>
                        </li>
                        <li>
                            <LinkBox to={youtube_URL}><img
                                style={{width: "2rem", height: "auto"}} src={youtubeIcon}
                                alt={"youtube_icon"}/></LinkBox>
                        </li>
                        <li>
                            <LinkBox to={website_URL}><img
                                style={{width: "2rem", height: "auto"}} src={websiteicon}
                                alt={"redaula_icon"}/></LinkBox>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}
