import React from 'react';
import './MenuLateral.css'
import {Link} from "react-router-dom";

function MenuLateralSeccion(props) {


    const handleClick = (event, index) => {
        let seccion_contenido = document.getElementById(`menu-lateral-${index}-contenido`)
        seccion_contenido.style.display === 'block' ?
            seccion_contenido.style.display = "none" : seccion_contenido.style.display = "block";

        let contenido = document.getElementsByClassName("menu-lateral-contenido")

        for (let i = 0; i < contenido.length; i++) {
            if (contenido[i] !== seccion_contenido)
                contenido[i].style.display = "none"
        }

    }

    return (
        <>
            <button className={`menu-lateral-button menu-lateral-${props.index}-button`}
                    onClick={event => handleClick(event, props.index)}>{props.nombreSeccion}
            </button>
            <div id={`menu-lateral-${props.index}-contenido`} className={"menu-lateral-contenido"}
                 style={{display: "block"}}>
                {props.subsecciones.map((subseccion, index) => {
                    return (
                        <Link key={index} style={{display: "block", width: "100%"}} reloadDocument={true}
                              to={subseccion.path}>
                            {subseccion.nombre}
                        </Link>
                    )
                })}
            </div>
        </>
    );
}

export default function MenuLateral(props) {
    const secciones = props.secciones

    return (
        <div className={"menu-lateral-wrapper"}>
            <div className={"menu-lateral"}>
                <ul>
                    {secciones.map((seccion, index) => {
                            return (
                                <li key={index}>
                                    <MenuLateralSeccion index={index} nombreSeccion={seccion.nombre}
                                                        subsecciones={seccion.subsecciones}/>
                                </li>
                            )
                        }
                    )}
                </ul>
            </div>
        </div>
    )
}

