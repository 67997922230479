import React, {useEffect, useState} from 'react';
import {getRequest2} from '../../../../requests/requests'
import Tabla, {
    TablaCabecera,
    TablaCelda,
    TablaCuerpo,
    TablaFila
} from "../../../../componentes/tablas/Tabla";
import './NoticiaAdminPage.css'
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";


export default function NoticiasAdminPage() {
    const [noticias, setNoticias] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        getRequest2('noticias/admin/noticias/', setNoticias)
    }, [])

    const tabla_cabeceras = ["Fecha", "Imagen", "Titulo", "Estado", "Acciones"]

    const detalleRuta = '/admin/noticias/detalle'
    const editarRuta = '/admin/noticias/editar'
    const eliminarRuta = '/admin/noticias/eliminar'

    function handleClick(event, ruta, id) {
        Swal.fire({
            title: "¿Está seguro que desea continuar?",
            text: "Al eliminar la noticia no se podrá recuperar nuevamente",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
        }).then(navigate(`${ruta}/${id}`)
        )
    }

    if (noticias.length > 0) {
        return (
            <div className={"admin-pagina"}>
                <h1>Administración de Noticias</h1>
                <Tabla>
                    <TablaCabecera cabeceras={tabla_cabeceras}/>
                    <TablaCuerpo>
                        {noticias.map((noticia, index) => {
                            return (
                                <TablaFila key={index}>
                                    <TablaCelda>{noticia.fecha}</TablaCelda>
                                    <TablaCelda><img src={noticia.imagen}
                                                     style={{width: "100px", height: "100%"}}
                                                     alt={`Imagen${index}`}/></TablaCelda>
                                    <TablaCelda>{noticia.titulo}</TablaCelda>
                                    <TablaCelda>{noticia.estado}</TablaCelda>
                                    <TablaCelda>
                                        <button onClick={
                                            (e) => handleClick(e, detalleRuta, noticia.id)}>
                                            Detalle
                                        </button>
                                        <button onClick={
                                            (e) => handleClick(e, editarRuta, noticia.id)}>
                                            Editar
                                        </button>
                                        <button onClick={
                                            (e) => handleClick(e, eliminarRuta, noticia.id)}>
                                            Eliminar
                                        </button>
                                    </TablaCelda>
                                < /TablaFila>
                            )
                        })}
                    </TablaCuerpo>
                </Tabla>
            </div>
        )
    }
    return null;
}

